import React from "react";
const Team = () => {
    return (
        <div>
        <div className='p-5 bg-primary mt-5' style={{ width :'100%', height:'300px'}}>
        <h1 className='d-flex justify-content-center mt-5 text-white' style={{ fontSize: '3.5vw'}}><b>Team</b></h1>
        <div className="d-flex justify-content-center">
         <button className="btn btn-primary border-0 text-white">Home</button>
         <button className="btn btn-primary border-0 text-white">Team</button>
         </div>
        </div> 
        </div>
    );
};
export default Team; 
